import React from 'react';
import PropTypes from 'prop-types';

import FavoritingHeartAnimation from './animation/FavoritingHeartAnimation';
import FavoritingHeartStyles from './FavoritingHeart.styled';

const FavoritingHeart = ({ isFavorited, onClick, ...props }) => {
  return (
    <FavoritingHeartStyles
      className="favoriting-heart-container"
      onClick={onClick}
      {...props}
    >
      <FavoritingHeartAnimation isFavorited={isFavorited} />
    </FavoritingHeartStyles>
  );
};

FavoritingHeart.propTypes = {
  isFavorited: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoritingHeart;
