import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import Heart from './Heart';

const HeartAnimationFave = ({ padding }) => {
  const pulse = keyframes`
        0% {
            fill: none;
            stroke: #24292D;
            transform: scale(1);
        }
        25% {
            fill: none;
            stroke: #24292D;
            transform: scale(0);
        }
        50% {
            fill: ${Colors.accent.ladyBug.standard};
            stroke: none;
            transform: scale(1.3);
        }
        75% {
            fill: ${Colors.accent.ladyBug.standard};
            stroke: none;
            transform: scale(.7);
        }
        100% {
            fill: ${Colors.accent.ladyBug.standard};
            stroke: none;
            transform: scale(1);
        }
    `;

  const HeartAnimationContainer = styled.div`
    width: 60px;
    height: 60px;

    .heart {
      cursor: pointer;
      fill: ${Colors.accent.ladyBug.standard};
      animation: ${pulse} 1s ease;
    }
  `;

  return (
    <HeartAnimationContainer>
      <Heart circleSize="28px" heartSize="18px" padding={padding} />
    </HeartAnimationContainer>
  );
};

HeartAnimationFave.propTypes = {
  padding: PropTypes.any,
};

export default HeartAnimationFave;
