import styled from 'styled-components';

const FavoritingHeartStyles = styled.div`
  display: flex;
  justify-content: 'flex-start';

  .favoriting-heart-circle {
    background-color: rgba(255, 255, 255);
    box-shadow: rgba(81, 88, 97, 0.2) 0 0 8px 0;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
  }
`;

export default FavoritingHeartStyles;
