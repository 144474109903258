import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import HeartAnimationFave from './HeartAnimationFave';
import HeartAnimationUnFave from './HeartAnimationUnFave';

import Colors from 'pubweb-smokey/dist/colors';
import Heart from './Heart';
import FavoritingHeartAnimationStyles from './FavoritingHeartAnimation.styled';

const FavoritingHeartAnimation = ({ isFavorited, padding, onClick }) => {
  const [unfavoritingAnimationVisible, setUnfavoritingAnimationVisible] =
    useState(false);
  const [favoritingAnimationVisible, setFavoritingAnimationVisible] =
    useState(false);
  const [prevIsFavorited, setPrevIsFavorited] = useState(isFavorited);

  useEffect(() => {
    if (isFavorited !== prevIsFavorited) {
      setFavoritingAnimationVisible(isFavorited);
      setUnfavoritingAnimationVisible(!isFavorited);
      setPrevIsFavorited(isFavorited);
    }
  }, [isFavorited]);

  let iconDisplayed;

  if (unfavoritingAnimationVisible) {
    iconDisplayed = <HeartAnimationUnFave padding={padding} />;
  } else if (favoritingAnimationVisible) {
    iconDisplayed = <HeartAnimationFave padding={padding} />;
  } else {
    iconDisplayed = (
      <Heart
        circleSize="28px"
        heartSize="18px"
        padding={padding}
        style={
          isFavorited
            ? {
                fill: Colors.accent.ladyBug.standard,
                stroke: 'none',
              }
            : { fill: 'none', stroke: '#24292D' }
        }
      />
    );
  }

  return (
    <FavoritingHeartAnimationStyles>
      <div className="favoriting-heart-circle" onClick={onClick}>
        <div className="heart-static">{iconDisplayed}</div>
      </div>
    </FavoritingHeartAnimationStyles>
  );
};

FavoritingHeartAnimation.propTypes = {
  isFavorited: PropTypes.bool,
  onClick: PropTypes.func,
  padding: PropTypes.any,
};

export default React.memo(FavoritingHeartAnimation);
