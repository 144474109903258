import styled from 'styled-components';

const FavoritingHeartAnimationStyles = styled.div`
  .favoriting-heart-circle {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(81, 88, 97, 0.2);
    height: 28px;
    width: 28px;
  }
`;

export default FavoritingHeartAnimationStyles;
