import React from 'react';
import PropTypes from 'prop-types';

const Heart = ({
  circleSize = '28px',
  heartSize = '18px',
  padding = '6px 5px 5px 5px',
  style,
}) => {
  const circleStyles = {
    width: circleSize,
    height: circleSize,
    padding: padding,
    boxSizing: 'border-box',
  };

  return (
    <div className="heart-container" style={circleStyles}>
      <svg
        width={heartSize}
        height={heartSize}
        className="heart"
        style={style}
        viewBox="0 0 16 13"
        version="1.1"
      >
        <title>Heart</title>
        <g transform="translate(-6.000000, -8.000000)">
          <path d="M20.1793455,14.5240604 C20.6843636,13.9387555 21,13.1963218 21,12.3753236 C21,10.5113829 19.4330182,9 17.5005091,9 C15.5669818,9 14,10.5113829 14,12.3753236 C14,10.5113829 12.4330182,9 10.5005091,9 C8.56698182,9 7,10.5113829 7,12.3753236 C7,13.1963218 7.31563636,13.9387555 7.82065455,14.5240604 L7.78501818,14.5240604 L7.93061818,14.6527096 C8.05789091,14.7862691 8.19636364,14.9041157 8.34298182,15.01607 L14,20 L19.6570182,15.0150879 C19.8036364,14.9041157 19.9431273,14.785287 20.0693818,14.6527096 L20.2149818,14.5240604 L20.1793455,14.5240604 Z"></path>
        </g>
      </svg>
    </div>
  );
};

Heart.propTypes = {
  circleSize: PropTypes.string,
  heartSize: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.any,
};

export default Heart;
