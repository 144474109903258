import { useEffect } from 'react';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';

export const useHorizontalSwipe = (elementId) => {
  // On mobile (touch device), the carousel sometimes moves up/down vertically when swiping.
  // Added this code to detect horizontal swipe and prevent the up/down scrolling.
  let startClientPos = { X: 0, Y: 0 };
  let clientPos = { X: 0, Y: 0 };

  const handleTouchStart = (e) => {
    startClientPos.X = e.touches[0].clientX;
    startClientPos.Y = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    clientPos.X = e.touches[0].clientX;
    clientPos.Y = e.touches[0].clientY;
    const diff = {
      X: clientPos.X - startClientPos.X,
      Y: clientPos.Y - startClientPos.Y,
    };
    const absY = Math.abs(diff.Y);
    const absX = Math.abs(diff.X);
    if (absX > absY) {
      // horizontal swipe - suppress scroll on touches
      e.preventDefault();
    }
    // else vertical
  };

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);
};

// Detect horizontal left or right swipe on an element and fire a google tag manager event (if event name is provided).
export const useHorizontalSwipeGtm = (elementId, gtm_event_name) => {
  let touchPosition = null;

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    touchPosition = touchDown;
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5 && gtm_event_name) {
      pushGTMEvent(gtm_event_name + '-left');
    }

    if (diff < -5 && gtm_event_name) {
      pushGTMEvent(gtm_event_name + '-right');
    }

    touchPosition = null;
  };

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove);
    }
    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);
};

export const useHorizontalDragGtm = (elementId, gtm_event_name) => {
  let startClientPosX = null;
  let clientPosX = 0;
  const DRAG_THRESHOLD_X = 10;

  const handleMouseDown = (e) => {
    startClientPosX = e?.clientX;
  };

  const handleMouseUp = () => {
    startClientPosX = null;
  };

  const handleMouseMove = (e) => {
    clientPosX = e?.clientX;
    // specific check for null because 0 also evaluates as false
    if (clientPosX !== null && startClientPosX !== null) {
      const diff = clientPosX - startClientPosX;

      if (diff > DRAG_THRESHOLD_X) {
        startClientPosX = null;
        if (gtm_event_name) {
          pushGTMEvent(gtm_event_name + '-right');
        }
      }

      if (diff < -DRAG_THRESHOLD_X) {
        startClientPosX = null;
        if (gtm_event_name) {
          pushGTMEvent(gtm_event_name + '-left');
        }
      }
    }
  };

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('mousemove', handleMouseMove);
      element.addEventListener('mousedown', handleMouseDown);
      element.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      if (element) {
        element.removeEventListener('mousemove', handleMouseMove);
        element.removeEventListener('mousedown', handleMouseDown);
        element.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, []);
};
