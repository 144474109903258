import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import Heart from './Heart';

const HeartAnimationUnFave = ({ padding }) => {
  const pulse = keyframes`
        0% {
            fill: ${Colors.accent.ladyBug.standard};
            stroke: none;
            transform: scale(1);
        }
        50% {
            transform: scale(0);
        }
        100% {
            fill: none;
            stroke: #24292D;
            transform: scale(1);
        }
    `;
  const HeartAnimationContainer = styled.div`
    width: 60px;
    height: 60px;

    .heart {
      fill: none;
      stroke: #24292d;
      animation: ${pulse} 0.5s ease;
    }
  `;

  return (
    <HeartAnimationContainer>
      <Heart circleSize="28px" heartSize="18px" padding={padding} />
    </HeartAnimationContainer>
  );
};

HeartAnimationUnFave.propTypes = {
  padding: PropTypes.any,
};

export default HeartAnimationUnFave;
